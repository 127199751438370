//type
import { PopUpFormProps } from '@interfaces/popup/helpers/PopUpForm';

//library
import React from 'react';
import { Formik, Form } from 'formik';
import Questions from '@components/layout/Questions';

/**
 * Create a popup for user
 *
 * @param {PopUpFormProps} props - Current user profile
 * @returns {React.Element} - Popup for additional questions
 */
const PopUpForm: React.FC<PopUpFormProps> = ({
    onSubmit,
    questions,
    setIsClosed
}: PopUpFormProps) => {
    const initialValues = {
        answer1: [],
        answer2: ''
    };

    return (
        <div className="hidden md:block md:fixed md:z-50 md:inset-0 md:bg-black md:bg-opacity-50">
            <div className="flex justify-center items-center h-screen ">
                <div className="w-auto md:w-3/4 lg:w-2/3 xl:w-1/2" role="dialog" aria-modal="true">
                    <Formik
                        initialValues={initialValues}
                        onSubmit={async (values, { setSubmitting }) => {
                            await onSubmit({ answer1: values.answer1, answer2: values.answer2 });
                            setSubmitting(false);
                        }}>
                        <Form>
                            <div className=" bg-white shadow-xl rounded-lg">
                                <Questions questions={questions} />
                            </div>

                            <div className="justify-between flex pt-10">
                                <button
                                    type="button"
                                    className="w-32 py-2 rounded-md border border-gray-300 shadow-sm text-base font-medium text-white focus:outline-none"
                                    onClick={() => setIsClosed(true)}>
                                    Later
                                </button>
                                <button
                                    type="submit"
                                    className="w-32 py-2 rounded-md border border-transparent shadow-sm bg-light-blue text-base font-medium text-white focus:outline-none">
                                    Submit
                                </button>
                            </div>
                        </Form>
                    </Formik>
                </div>
            </div>
        </div>
    );
};

export default PopUpForm;
