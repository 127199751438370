//Library
import React, { ReactElement } from 'react';
import Head from 'next/head';

//code
import SearchForm from '@components/search/SearchForm';
import Header from '@components/layout/Header';
import PopUp from '@components/popup/PopUp';

/**
 * Get the search page.
 *
 * @returns {ReactElement} - The index page.
 */
const Home: React.FC = (): ReactElement => {
    return (
        <>
            <Head>
                <title>Solar AI: Search</title>
                <link rel="icon" href="/favicon.ico" />
            </Head>
            <PopUp />
            <Header type="noSearch" />
            <div className="flex h-screen">
                <div className="md:m-auto my-auto p-2 md:w-7/12 lg:w-5/12 w-full">
                    <SearchForm type="home" />
                </div>
            </div>
        </>
    );
};

export default Home;
