//type
import {
    GetProfileQuery,
    ProfileInput,
    UpdateProfileMutation,
    Answer
} from '@interfaces/popup/PopUp';

//library
import React, { ReactElement, useEffect, useState } from 'react';
import getProfileData from '@components/helpers/getProfileData';
import { API } from 'aws-amplify';
import { updateProfile } from '@components/graphql/mutations';
import PopUpForm from '@components/popup/helpers/PopUpForm';
import { GraphQLResult } from '@aws-amplify/api';

/**
 * Create a popup for user
 *
 * @returns {ReactElement} - The pop up questions
 */
const Popup: React.FC = (): ReactElement => {
    const [isClosed, setIsClosed] = useState<boolean>(false);
    const [profile, setProfile] = useState<GetProfileQuery['getProfile']>(null);
    const questions: Answer = [
        {
            question: 'What is your role?',
            options: [
                'EPC / Installer',
                'Project Developer',
                'Electricity Retailer',
                'Facilities Services',
                'Others'
            ],
            type: 'checkbox',
            selected: []
        },
        {
            question: 'How many people work at your company?',
            options: ['Just myself', '2 -10', '11 - 50', '51 -250', '251 - 1000', '>1000'],
            type: 'radio',
            selected: []
        }
    ];

    useEffect(() => {
        /**
         * Get the profile data
         *
         * @returns {Object} Props
         */
        async function fetchData(): Promise<void> {
            const data: GetProfileQuery['getProfile'] = await getProfileData({ API });
            setProfile(data);
        }
        fetchData();
    }, []);

    /**
     * Save the answer on the user profile.
     *
     * @param {string[] | string} params - The answers to the pop-up question
     */
    const onSubmit = async ({
        answer1,
        answer2
    }: {
        answer1: string[] | string;
        answer2: string[] | string;
    }): Promise<void> => {
        if (!answer1.length || !answer2) {
            setIsClosed(true);
            return;
        }

        const input: ProfileInput = {
            ...profile,
            answer: questions
        } as ProfileInput;

        input.answer[0].selected = answer1 as string[];
        input.answer[1].selected = [answer2] as string[];

        (await API.graphql({
            query: updateProfile,
            variables: { input }
        })) as GraphQLResult<UpdateProfileMutation>;
        setIsClosed(true);
    };

    return (
        <>
            {/* We only show the popup if the questions where not answered (!profile.answer.length)*/}
            {profile && profile.answer && Boolean(!profile.answer.length) && (
                <div className={`${isClosed ? 'hidden' : ' '}`}>
                    <PopUpForm
                        onSubmit={onSubmit}
                        questions={questions}
                        setIsClosed={setIsClosed}
                    />
                </div>
            )}
        </>
    );
};

export default Popup;
