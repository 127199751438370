//type
import { QuestionsProps, Answer } from '@interfaces/layout/Questions';

//CSS
import styles from '@components/layout/css/Radios.module.css';

//library
import React from 'react';
import { Field } from 'formik';

/**
 * Create the questions
 *
 * @param {ProfileFormProps} props - Current questions
 * @returns {React.Element} - Return the view of the questions
 */
const Questions: React.FC<QuestionsProps> = ({ questions }: QuestionsProps) => {
    return (
        <>
            {questions
                .slice(Math.max(questions.length - 2, 0))
                .map((question: Answer[0], index: number) => (
                    <div key={index}>
                        <div
                            className={`text-navy font-bold px-8 py-8 border-b ${
                                index && 'border-t'
                            }`}>
                            <div>
                                {index + 1}. {question.question}
                            </div>
                        </div>

                        {question.type == 'checkbox' && (
                            <div className="px-8 py-8 grid grid-cols-2 gap-y-4 gap-x-8 md:grid-cols-3 lg:grid-cols-3 ">
                                {question.options.map(
                                    (value: typeof question.options[0], indexOptions: number) => (
                                        <label key={indexOptions}>
                                            <Field
                                                type="checkbox"
                                                name={`answer${index + 1}`}
                                                value={value}
                                            />
                                            <span className="ml-2 text-navy">{value}</span>
                                        </label>
                                    )
                                )}
                            </div>
                        )}
                        {question.type == 'radio' && (
                            <div
                                className={`px-8 py-8 grid grid-cols-2 gap-y-4 gap-x-8 md:grid-cols-3 lg:grid-cols-4 ${styles.radios}`}>
                                {question.options.map(
                                    (value: typeof question.options[0], indexOptions: number) => (
                                        <div key={indexOptions}>
                                            <Field
                                                id={value}
                                                type="radio"
                                                name={`answer${index + 1}`}
                                                value={value}
                                            />
                                            <label
                                                htmlFor={value}
                                                className="inline-block py-2 text-gray-500 rounded-full text-center bg-gray-300 w-full ">
                                                {value}
                                            </label>
                                        </div>
                                    )
                                )}
                            </div>
                        )}
                    </div>
                ))}
        </>
    );
};

export default Questions;
