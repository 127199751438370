//type
import {
    GetProfileDataProps,
    GetProfileDataReturn,
    GetProfileQuery
} from '@interfaces/helpers/getProfileData';
import { GraphQLResult } from '@aws-amplify/api';

//code
import { getProfile } from '@components/graphql/queries';

/**
 * Get the data of the profile on the frontend
 *
 * @param {GetProfileDataProps} props - The Api object we want to call
 * @returns {GetProfileDataReturn} - The profile data
 */
const getProfileData = async ({ API }: GetProfileDataProps): Promise<GetProfileDataReturn> => {
    const response: GraphQLResult<GetProfileQuery> = (await API.graphql({
        query: getProfile
    })) as GraphQLResult<GetProfileQuery>;

    return response.data?.getProfile;
};

export default getProfileData;
